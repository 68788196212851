import reactdomclient from "react-dom/client";
import { BrowserRouter, Routes, Route, useNavigate} from "react-router-dom";
import React, { useState, useEffect } from "react";
import { getCartByUser } from "./api";
// css stylesheets can be created for each component
// place them in the src/style directory, and import them like this:
import './style/index.css';
import { SliderData } from "./components/SliderData";


import {
    Products,
    Home,
    Login,
    Register,
    SingleProduct,
    Navbar,
    Profile,
    EditProduct,
    Cart,
    Order
} from "./components";

const App = () => {
    const [loggedIn, setLoggedIn] = useState(false);
    const [token, setToken] = useState("");
    const [userId, setUserId] = useState(0);
    const [username, setUsername] = useState("");
    const [isAdmin, setIsAdmin] = useState(false);
    const [productId, setProductId] = useState("");
    const [cart, setCart] = useState([]);
    const [choiceFilter, setChoiceFilter] = useState(false);
    const [usdaFilter, setUsdaFilter] = useState(false);
    const [standardFilter, setStandardFilter] = useState(false);
    const [ orderId, setOrderId ] = useState(0);
    const [editProduct, setEditProduct] = useState(false);
    const [productName, setProductName] = useState('');
    const [productDescription, setProductDescription] = useState('');
    const [pricePerOz, setPricePerOz] = useState(0);
    const [quantity, setQuantity] = useState(0);
    const [grade, setGrade] = useState('');
    const [image, setImage] = useState('');
    

    const navigate = useNavigate();
    
    const gettingCartByUser = async () => {
        try {
            const cart = await getCartByUser(userId, token);
            setCart(cart);
        } catch (err) {
            console.error("Unable to fetch cart by user", err);
        }
    };

    useEffect(()=>{
        let savedToken = localStorage.getItem("token");
        let savedUserId = localStorage.getItem("userId");
        let savedUsername = localStorage.getItem("user");
        let savedIsAdmin = localStorage.getItem("isAdmin");
        if(savedToken){
            setLoggedIn(true);
            setToken(savedToken);
        }
        if(savedUserId){
            setUserId(Number(savedUserId));
        }
        if(savedUsername){
            setUsername(savedUsername);
        }
        if(savedIsAdmin){
            setIsAdmin(savedIsAdmin);
        }
        gettingCartByUser();
    }, [token])

    function logOut(){
        localStorage.removeItem("token");
        localStorage.removeItem("userId");
        localStorage.removeItem("user");
        localStorage.removeItem("isAdmin");
        setLoggedIn(false);
        setToken("");
        setUserId(0);
        setUsername("");
        setIsAdmin(false);
        alert("You've successfully logged out and you're being redirected to the homepage!")
        navigate("/");
    }

    return <>
    <Navbar loggedIn={loggedIn} logOut={logOut} cart={cart} choiceFilter={choiceFilter} setChoiceFilter={setChoiceFilter} usdaFilter={usdaFilter} setUsdaFilter={setUsdaFilter} standardFilter={standardFilter} setStandardFilter={setStandardFilter}/>
    <Routes>
        <Route path="/" element={<Home slides={SliderData}/>}></Route>
        <Route path="products" element={<Products setProductId={setProductId} choiceFilter={choiceFilter} usdaFilter={usdaFilter} standardFilter={standardFilter} />}></Route>
        <Route path="product" element={<SingleProduct setProductId={setProductId} productId={productId} cart={cart} token={token} loggedIn={loggedIn} setEditProduct={setEditProduct} setProductName={setProductName} setProductDescription={setProductDescription} setPricePerOz={setPricePerOz}
            quantity={quantity} setQuantity={setQuantity} setGrade={setGrade} setImage={setImage} isAdmin={isAdmin} userId={userId} gettingCartByUser={gettingCartByUser} editProduct={editProduct} />}></Route>
        <Route path = "login" element={<Login setToken={setToken} setLoggedIn={setLoggedIn} setUserId={setUserId} username={username} setUsername={setUsername} setIsAdmin={setIsAdmin}/>}></Route>
        <Route path = "register" element={<Register setToken={setToken} setLoggedIn={setLoggedIn} setUserId={setUserId} username={username} setUsername={setUsername} userId={userId}/>}></Route>
        <Route path = "profile" element={<Profile userId={userId} token={token}/>}></Route>
        <Route path="cart" element={<Cart userId={userId} token={token} cart={cart} gettingCartByUser={gettingCartByUser} setOrderId={setOrderId}/>}></Route>
        <Route path="order" element={<Order orderId={orderId} userId={userId} token={token}/>}></Route>
    </Routes>

    { editProduct ? <EditProduct token={token} setEditProduct={setEditProduct} productId={productId} setProductName={setProductName} productName={productName} setProductDescription={setProductDescription} productDescription={productDescription} setPricePerOz={setPricePerOz} pricePerOz={pricePerOz}
            setQuantity={setQuantity} quantity={quantity} setGrade={setGrade} grade={grade} setImage={setImage} image={image}/> : null}
    </>

    
};

const root = reactdomclient.createRoot(document.getElementById("App"));
root.render(<BrowserRouter><App/></BrowserRouter>);
