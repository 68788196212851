import React from "react"
import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

export default function Navbar ({loggedIn, logOut, cart, choiceFilter, setChoiceFilter, usdaFilter, setUsdaFilter, standardFilter, setStandardFilter}) {
    const [hoverProducts, setHoverProducts] = useState(false);

    
    // useEffect(() => {
    //     if (!hoverProducts )
    // })
    
    return (<>
        <section id="navbar">
            <div className="header-link-container">
                
                <Link to="products" className="header-link" onMouseEnter={() => {
                    setHoverProducts(true);
                }} >Products</Link>
                {loggedIn ? <Link to="profile" className="header-link" onMouseEnter={() => {
                    setHoverProducts(false);
                }}>Profile</Link> : null}
            </div>
            <Link to="/" id="title" onMouseEnter={() => {
                    setHoverProducts(false);
                }} >The Right Cut</Link>
            <div>
                {
                    loggedIn ? <Link to="login" className="header-link" onMouseEnter={() => {
                        setHoverProducts(false);
                    }} onClick={() => {
                        logOut();
                    }}>Log Out</Link> : <Link to="login" className="header-link" onMouseEnter={() => {
                        setHoverProducts(false);
                    }} >Log In</Link>
                }
                <Link to="cart" className="header-link" onMouseEnter={() => {
                        setHoverProducts(false);
                    }} >Cart [{cart.length}]</Link>
            </div>
        </section>
        { hoverProducts ? <>
        <section id="filterNavbar" onMouseEnter={() => {
                    setHoverProducts(true);
                }} onMouseLeave={() => {
                    setHoverProducts(false)
                }} >
            <div>
                <div>Filter by quality</div>
                <input type="checkbox" checked={standardFilter} onChange={() => {
                    setStandardFilter(!standardFilter);
                    }
                }/>
                <span>Standard</span>
                <input type="checkbox" checked={choiceFilter} onChange={() => {
                    setChoiceFilter(!choiceFilter);
                    }
                }/>
                <span>Choice</span>
                <input type="checkbox" checked={usdaFilter} onChange={() => {
                    setUsdaFilter(!usdaFilter);
                    }
                }/>
                <span>USDA Prime</span>
            </div>
        </section> 
        </> : null}
        
    </>
    );
};