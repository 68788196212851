import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { deleteSingleItemFromCart, editCartProductQuantity, emptyCart, getCartQuantitiesByUser, createOrder, addItemsToOrder, addCostToOrder } from "../api"
import "../style/cart.css";

export default function Cart({userId, token, cart, gettingCartByUser, setOrderId}) {
    const [ cartQuantity, setCartQuantity ] = useState([]);
    const [ changeState, setChangeState ] = useState([]);
    const [ totalCost, setTotalCost ] = useState(0);
    const navigate = useNavigate();

    const getCartQuantities = async () => {
        try {
            const cartQuantities = await getCartQuantitiesByUser(userId, token);
            setCartQuantity(cartQuantities);
        } catch (err) {
            console.error("Failed to get cart quantities by user", err);
        }
    };

    const createCartChangeStates = () => {
        const changeStateCopy = [...changeState];
        for(let i=0; i<cart.length; i++) {
            changeStateCopy.push({ state: false});
        };
        setChangeState(changeStateCopy);
    };

    const addToTotalCost = () => {
        let cost = 0;
        for(let i=0; i<cart.length; i++) {
            cost += cart[i].products[0].oz * cart[i].products[0].priceperoz * cart[i].products[0].quantity;
        }
        setTotalCost(cost);
    };

    const creatingOrder = async () => {
        try {
            const order = await createOrder(userId, token);
            setOrderId(order.order.id);
            for(let i=0; i<cart.length; i++) {
                await addItemsToOrder(token, order.order.id, cart[i].products[0].id, cart[i].products[0].quantity);
            }
            await addCostToOrder(token, order.order.id, totalCost);
        } catch (err) {
            console.error("Unable to create order", err);
        }
    };

    const refreshCart = async () => {
        try {
            await gettingCartByUser();
            await getCartQuantities();
        } catch (err) {
            console.error("Failed to refresh cart", err);
        }
    };

    useEffect(() => {
        gettingCartByUser();
        getCartQuantities();
        if(cart) {
            createCartChangeStates();
            addToTotalCost();
        };
    }, []);

    useEffect(() => {
        refreshCart();
    }, [changeState]);

    useEffect(() => {
        addToTotalCost();
    }, [cartQuantity, cart]);

    return <>
        <div className="cart-background">
            <div className="cart-foreground">
                <div className="cart-title">Cart</div>
                {
                    cart[0] && changeState[0] ? cart.map((product, index) => {
                        return <div key={product.id}>
                            {
                                product.products.map((item) => {
                                    return <div key={item.id} className="cart-item-container">
                                        <div className="cart-items">
                                            <img src={item.image} className="cart-image"></img>
                                            <div className="cart-item-info">
                                                <div>Product: {item.name}</div>
                                                <div>Size: {item.oz} oz.</div>
                                                <div>${item.priceperoz} per oz.</div>
                                            {
                                                !changeState[index].state ? <div className="cart-edit">
                                                    <div>Quantity: {item.quantity}</div>
                                                    <button className="cart-edit-button" onClick={() => {
                                                        let copy = [...changeState];
                                                        copy[index].state = true;
                                                        setChangeState(copy);
                                                        }}><span className="material-icons">edit</span></button>
                                                </div> : 
                                                <form className="cart-edit-form" onSubmit={ async (event) => {
                                                    event.preventDefault();
                                                    await editCartProductQuantity(product.id, userId, token, item.id, cartQuantity[index].quantity);
                                                    let copy = [...changeState];
                                                    copy[index].state = false;
                                                    setChangeState(copy);
                                                }}>
                                                    <label>Quantity:</label>
                                                    <input type="number" min="1" max="999" maxLength="3" value={cartQuantity[index].quantity} onChange={(event) => {
                                                        let copy = [...cartQuantity];
                                                        copy[index].quantity = event.target.value;
                                                        setCartQuantity(copy);
                                                    }}></input>
                                                    <button type="submit" className="material-icons">done</button>
                                                </form>
                                        }
                                                <div>Price: ${item.oz * item.priceperoz * item.quantity}</div>
                                            </div> 
                                        </div>
                                        <button className="cart-delete-button" onClick={ async (event) => {
                                                event.preventDefault();
                                                await deleteSingleItemFromCart(userId, token, item.id);
                                                await refreshCart();
                                            }}><span className="material-icons">delete</span></button>
                                    </div>
                                })
                            }
                        </div>
                    }) : <div className="empty-cart-container">
                            <div className="empty-cart-message">There are no items in your cart</div>
                            <button className="browse-products" onClick={() => {navigate("/products")}}>Browse products</button>
                        </div>   
                }
                {
                    cart[0] && changeState[0] ? <div className="cart-bottom-container">
                        <div className="cart-total-price">Total: ${totalCost}</div>
                        <div className="cart-bottom-buttons">
                            <button onClick={(event) => {
                                event.preventDefault();
                                emptyCart(userId, token);
                                refreshCart();
                            }}>Clear cart</button>
                            <button onClick={ async (event) => {
                                event.preventDefault();
                                await creatingOrder();
                                await emptyCart(userId, token);
                                await refreshCart();
                                navigate("/order");
                            }}>Place order</button>
                        </div>
                    </div> : null
                }
            </div> 
        </div>
    </>
};