import React from "react";
import { editProduct } from "../api";



export default function EditProduct ({token, setEditProduct, productId, setProductName, productName, setProductDescription, productDescription, setPricePerOz, pricePerOz, setQuantity, quantity, setGrade, grade, setImage, image }) {
    
    
    return <form className="editProduct" onSubmit={(event) => {
        event.preventDefault();
        editProduct(token, productId, productName, productDescription, pricePerOz, quantity, grade, image  );
        setEditProduct(false);
    }}>
        <h2>Edit Product</h2>
        <div className="form-element">
            <label>Product Name</label>
            <input className="editInput" type="string" value={productName} required onChange={event => {setProductName(event.target.value)}}></input>
        </div>
        <div className="form-element">
            <label>Description</label>
            <input className="editInput" type="string" value={productDescription} required onChange={event => {setProductDescription(event.target.value)}}></input>
        </div>
        <div className="form-element">
            <label>priceperoz</label>
            <input className="editInput" type="number" value={pricePerOz} required onChange={event => {setPricePerOz(event.target.value)}}></input>
        </div>
        <div className="form-element">
            <label>Quantity</label>
            <input className="editInput" type="number" value={quantity} required onChange={event => {setQuantity(event.target.value)}}></input>
        </div>
        <div className="form-element">
            <label>Grade</label>
            <input className="editInput" type="string" value={grade} required onChange={event => {setGrade(event.target.value)}}></input>
        </div>
        <div className="form-element">
            <label>Image</label>
            <input className="editInput" type="string" value={image} required onChange={event => {setImage(event.target.value)}}></input>
        </div>
        <button type="submit">Submit</button>
        <button onClick={()=> {
            setEditProduct(false);
        }}>Cancel</button>
    </form>
}