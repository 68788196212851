import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getSingleOrderByUser } from "../api";
import "../style/order.css";

export default function Order({orderId, userId, token}) {
    const [ order, setOrder ] = useState({});
    const [ orderProducts, setOrderProducts ] = useState([]);
    const [ noOrder, setNoOrder ] = useState(false);
    const navigate = useNavigate();

    const gettingOrders = async () => {
        try {
            if(orderId !== 0) {
                const placedOrder = await getSingleOrderByUser(token, userId, orderId);
                setOrder(placedOrder);
                setOrderProducts(placedOrder.products);
            } else {
                setNoOrder(true);
            }
        } catch (err) {
            console.error("Unable to fetch single order", err);
        }
    };

    useEffect(() => {
        gettingOrders();
    }, []);

    return <>
        {
            noOrder ? <div className="order-container">
                <div>No order has been placed for you yet</div>
                <button onClick={() => {navigate("/products")}}>Continue Shopping</button>
            </div> :
            <div className="order-container">
                <div className="order-message">Order placed!</div>
                <div className="order-details-container">
                    <div className="order-id">Order ID: {order.id}</div>
                    <div className="order-date">{order.date}</div>
                    <div className="order-items-title">Items:</div>
                    { 
                        orderProducts.map((product) => {
                            return <div key={product.id} className="order-items">
                                <img className="order-image" src={product.image}></img>
                                <div className="single-order-item">
                                    <div className="order-product-name">{product.name}</div>
                                    <div>Amount: {product.quantity}</div>
                                    <div>Price: ${product.quantity * product.priceperoz * product.oz}</div>
                                </div>
                            </div>
                        }) 
                    }
                    <div className="order-status">Status: {order.status}</div>
                    <div className="order-total">Total: ${order.cost}</div>
                </div>
                <button className="continue-shopping-button" onClick={() => {navigate("/products")}}>Continue Shopping</button>
            </div>
        }
    </>
};