import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { registerUser } from "../api";


export default function Register({ username, setUsername, setToken, setLoggedIn, setUserId }) {
  const [lastname, setLastName] = useState("")
    const [firstname, setFirstName] = useState("")
  const [password, setPassword] = useState("")
  const [email, setEmail] = useState("")
  const navigate = useNavigate()

  return (

    <>
      <div className="registerField">
      <br></br>
      <br></br>
        <fieldset>
          
          
          <div className="formLogIn"><center>
            <div>Sign Up for The Right Cut</div>
            <br></br>
            <form onSubmit={async (event) => {
              event.preventDefault()
              if (password.length < 8) {
                alert("password must contain atleast 8 characters")
              } else {
                let result = await registerUser(username, password, firstname, lastname, email);

                if (result.user) {
                  setToken(result.token);
                  setLoggedIn(true);
                  setUserId(result.user.id);
                  setUsername(result.user.username);
                  localStorage.setItem("token", result.token);
                  localStorage.setItem("user", result.user.username);
                  alert("Sucessfully Registered for the right cut...Redirecting to your Home Page");
                  navigate("/");
                }
                else {
                  alert("Username Already Exists!");
                }
              }
            }}>
              {/* <label>Desired Username</label> */}
              <input type="text" placeholder="Desired Username"  required value={username} onChange={(event) => { setUsername(event.target.value) }}></input>
              <br></br>
              {/* <label>Desired Password</label> */}
              <input type="password" placeholder="Desired Password" required value={password} onChange={(event) => { setPassword(event.target.value) }}></input>
              <br></br>
              <input type ="text" placeholder="First name" required value={firstname} onChange={(event) => { setFirstName(event.target.value)}}></input>
              <br></br>
              <input type="text" placeholder="Last Name" required value={lastname} onChange={(event) => { setLastName(event.target.value)}}></input>
              <br></br>
              <input type="text" placeholder="Email" required value={email} onChange={(event) => { setEmail(event.target.value)}}></input>
              <br></br>
              <button className="register-btn" type="submit">REGISTER</button>
            </form>
            <br></br>
            <div>Already have the Right Cut Credentials?</div>
            <br></br>
            <Link to="/login">Log In Here</Link>
          </center></div>
        </fieldset>
        </div>
    </>

  )
}