import { getReviewsByUser, getOrdersByUser } from "../api";
import React, { useState, useEffect } from "react";
import '../style/profile.css'


export default function Profile ({userId, token}) {
const [myReviews, setMyReviews] = useState([])
const [myOrders, setMyOrders] = useState([])
const [processing, setProcessing] = useState(false)
const [inTransit, setInTransit] = useState(false)
const [delivered, setDelivered] = useState(false)
const [cancelled, setCancelled] = useState(false)

    const gettingReviews = async () => {
        try {
            const reviews = await getReviewsByUser(userId, token);
            setMyReviews(reviews.reviews);
        } catch (err) {
            throw err
        }
    }

    const gettingOrders = async () => {
        try {
            const orders = await getOrdersByUser(userId, token);
            setMyOrders(orders.userOrders);
        } catch (err) {
            throw err
        }
    }

    useEffect(() => {
        gettingReviews();
        gettingOrders();
    }, [token]);


    return (
        <div className="profile-container">
            { myReviews ? <h1 className="profile-headers">My Reviews</h1> : null }
                { myReviews ? 
                    myReviews.map((review) => {
                        return <div key={review.id} className="reviews">
                                <div>{review.comment}</div>
                                <div>Rating: {review.rating}</div>
                            </div>
                    })
                : null }
            
            { myOrders ? <><h1 className="profile-headers">My Orders</h1> 
                <div className="orderFilter">
                    <div>Filter by status:</div>
                    <input type="checkbox" checked={processing} onChange={() => {
                        setProcessing(!processing);
                        }
                    }/>
                    <span>Processing</span>
                    <input type="checkbox" checked={inTransit} onChange={() => {
                        setInTransit(!inTransit);
                        }
                    }/>
                    <span>In Transit</span>
                    <input type="checkbox" checked={delivered} onChange={() => {
                        setDelivered(!delivered);
                        }
                    }/>
                    <span>Delivered</span>
                    <input type="checkbox" checked={cancelled} onChange={() => {
                        setCancelled(!cancelled);
                        }
                    }/>
                    <span>Cancelled</span>
                </div>
            </> : null } 
            <div className="orders-container">
                { myOrders && processing ?
                    myOrders.map((order) => {
                    { return order.status.toLowerCase() === "processing" ? <div key={order.id} className="orders">
                        <div>{order.date}</div>
                        <div>{order.status}</div>
                        {
                            order.products.map((product) => {
                                return <div key = {product.id} className="products_ordered">
                                    <div>{product.name}</div>
                                    <div>{product.grade}</div>
                                </div>
                            })
                        }
                        <div>{order.cost}</div>
                    </div> : null }
                }) : null }
                { myOrders && inTransit ?
                    myOrders.map((order) => {
                    { return order.status.toLowerCase() === "in transit" ? <div key={order.id} className="orders">
                        <div>{order.date}</div>
                        <div>{order.status}</div>
                        {
                            order.products.map((product) => {
                                return <div key = {product.id} className="products_ordered">
                                    <div>{product.name}</div>
                                    <div>{product.grade}</div>
                                </div>
                            })
                        }
                        <div>{order.cost}</div>
                    </div> : null }
                }) : null }
                { myOrders && delivered ?
                    myOrders.map((order) => {
                    { return order.status.toLowerCase() === "delivered" ? <div key={order.id} className="orders">
                        <div>{order.date}</div>
                        <div>{order.status}</div>
                        {
                            order.products.map((product) => {
                                return <div key = {product.id} className="products_ordered">
                                    <div>{product.name}</div>
                                    <div>{product.grade}</div>
                                </div>
                            })
                        }
                        <div>{order.cost}</div>
                    </div> : null }
                }) : null }
                { myOrders && cancelled ?
                    myOrders.map((order) => {
                    { return order.status.toLowerCase() === "cancelled" ? <div key={order.id} className="orders">
                        <div>{order.date}</div>
                        <div>{order.status}</div>
                        {
                            order.products.map((product) => {
                                return <div key = {product.id} className="products_ordered">
                                    <div>{product.name}</div>
                                    <div>{product.grade}</div>
                                </div>
                            })
                        }
                        <div>{order.cost}</div>
                    </div> : null }
                }) : null }
                { myOrders && !inTransit && !processing && !delivered && !cancelled ?
                    myOrders.map((order) => {
                     return <div key={order.id} className="orders">
                        <div>{order.date}</div>
                        <div>{order.status}</div>
                        {
                            order.products.map((product) => {
                                return <div key = {product.id} className="products_ordered">
                                    <div>{product.name}</div>
                                    <div>{product.grade}</div>
                                </div>
                            })
                        }
                        <div>{order.cost}</div>
                    </div> 
                }) : null }
            </div>
            { !myOrders && !myReviews ?
                <div>
                    <h3>No activity yet. Go make some orders and leave some reviews.</h3>
                </div> : null

            }
        </div>
    )
}