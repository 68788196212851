export const SliderData = [
    {
        image: 'https://cdn.pixabay.com/photo/2016/01/13/17/21/steak-1138563__480.jpg'
    },
    {
        image: 'https://cdn.pixabay.com/photo/2015/12/08/19/08/steak-1083568__480.jpg'
    },
    {
        image: 'https://cdn.pixabay.com/photo/2016/08/13/07/22/beef-tenderloin-1590350__480.jpg'
    },
    {
        image: 'https://cdn.pixabay.com/photo/2015/12/08/19/08/steak-1083567__340.jpg'
    },
    {
        image: 'https://cdn.pixabay.com/photo/2017/02/24/21/18/steak-2096401__340.jpg'
    }
]