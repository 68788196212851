// API call functions go here
export const BASE_URL = 'https://the-right-cut-api.onrender.com/api' // change this link later when deploying
export async function loginUser(username, password) {
    try {
      const response = await fetch(`${BASE_URL}/users/login`, {
        method: "POST",
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            username: username,
            password: password
        })
      })
      let result = await response.json()
      console.log(result)
      return result;
    } catch (err) {
      console.log("Ahh couldn't log in!! " + err)
    }
  }

export async function registerUser(username, password, firstname, lastname, email) {

    try {

      const response = await fetch(`${BASE_URL}/users/register`, {
        method: "POST",
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          username: username,
          password: password,
          firstname: firstname,
          lastname: lastname,
          email: email

        })
      })
      let result = await response.json()
      console.log(result)
      return result;
    } catch (err) {
      console.log("Could not register " + err)
    }
  }

// Products

export async function getAllProducts() {
  try {
      const response = await fetch(`${BASE_URL}/products`);
      const result = await response.json();
      return result;
  } catch (err) {
      throw err;
  }
};

export async function getSingleProduct(id) {
  try {
    const response = await fetch(`${BASE_URL}/products/${id}`);
    const result = await response.json();
    return result;
  } catch (err) {
    throw err;
  }
};

export async function editProduct(token, productId, name, description, priceperoz, quantity, grade, image ) {
  try {
    console.log('here1')
    const response = await fetch(`${BASE_URL}/products/${productId}`, {
      method: "PATCH",
      headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`
      },
      body: JSON.stringify({
        name: name,
        description: description,
        priceperoz: priceperoz,
        quantity: quantity,
        grade: grade,
        image: image
      })
    })
    console.log('hereee');
    const result = await response.json();
    console.log(result)
    return result;
  } catch (err) {
    throw err;
  }
}


// Reviews

export async function getProductReviews(productId) {
  try {
    const response = await fetch(`${BASE_URL}/products/${productId}/reviews`);
    const result = await response.json();
    return result;
  } catch (err) {
    throw err;
  }
};

export async function addReview(productId, rating, comment, token){
    try{
        const response = await fetch(`${BASE_URL}/products/${productId}/reviews`,{
            method: "POST",
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({
                comment: comment,
                rating: rating
    
            })
        })
        const result = await response.json();
        console.log(result)
    }catch(error){
        console.error("was not able to add review")
    }
}
export async function getReviewsByUser(userId, token) {
  try {
    const response = await fetch(`${BASE_URL}/reviews/${userId}`, {
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${token}`
    }});
    const result = await response.json();
    return result;
  } catch (err) {
    throw err
  }
}

// Orders

export async function getOrdersByUser(userId, token) {
  try {
    const response = await fetch(`${BASE_URL}/orders/${userId}`, {
      headers: {
        "content-type": "application/json",
        "Authorization": `Bearer ${token}`
      }});
    const result = await response.json();
    return result;
  } catch (err) {
    throw err
  }
};

export async function getSingleOrderByUser(token, userId, orderId) {
  try {
    const response = await fetch(`${BASE_URL}/orders/${userId}/${orderId}`, {
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
      }
    });
    const result = await response.json();
    return result;
  } catch (err) {
    throw err;
  }
};

export async function createOrder(userId, token) {
  try {
    const response = await fetch(`${BASE_URL}/orders/${userId}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
      },
      body: JSON.stringify({
        date: Date(),
        status: "Processing"
      })
    });
    const result = response.json();
    return result;
  } catch (err) {
    throw err;
  }
};

export async function addItemsToOrder(token, orderId, productId, quantity) {
  try {
    await fetch(`${BASE_URL}/orders/${orderId}/products/${productId}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
      },
      body: JSON.stringify({
        quantity: quantity
      })
    });
  } catch (err) {
    throw err;
  }
};

export async function addCostToOrder(token, orderId, cost) {
  try {
    await fetch(`${BASE_URL}/orders/${orderId}/totalcost`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
      },
      body: JSON.stringify({
        cost: cost
      })
    });
  } catch (err) {
    throw err;
  }
};

// Cart

export async function getCartByUser(userId, token) {
  try {
    const response = await fetch(`${BASE_URL}/cart/user/${userId}`, {
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
      }
    });
    const result = await response.json();
    return result;
  } catch (err) {
    throw err;
  }
};

export async function getCartQuantitiesByUser(userId, token) {
  try {
    const response = await fetch(`${BASE_URL}/cart/quantity/user/${userId}`, {
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
      }
    });
    const result = await response.json();
    return result;
  } catch (err) {
    throw err;
  }
}

export async function addItemToCart(userId, token, productId, quantity) {
  try {
    await fetch(`${BASE_URL}/cart/user/${userId}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
      },
      body: JSON.stringify({
        productId: productId,
        quantity: quantity
      })
    });
  } catch (err) {
    throw err;
  }
};

export async function deleteSingleItemFromCart(userId, token, productId) {
  try {
    await fetch(`${BASE_URL}/cart/user/${userId}/product/${productId}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
      }
  })
  } catch (err) {
    throw err;
  }
};

export async function emptyCart(userId, token) {
  try {
    await fetch(`${BASE_URL}/cart/user/${userId}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
      }
    })
  } catch (err) {
    throw err;
  }
};

export async function editCartProductQuantity(cartId, userId, token, productId, quantity) {
  try {
    await fetch(`${BASE_URL}/cart/${cartId}/user/${userId}/product/${productId}`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
      },
      body: JSON.stringify({
        quantity: quantity
      })
    })
  } catch (err) {
    throw err;
  }
};