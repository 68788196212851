import React from "react"
import { useEffect, useState } from "react";
import { getAllProducts } from "../api";
import { Link } from "react-router-dom";
import "../style/products.css";

export default function Products ({setProductId, choiceFilter, usdaFilter, standardFilter}) {
    const [ products, setProducts ] = useState([]);

    const gettingProducts = async () => {
        try {
            const products = await getAllProducts();
            setProducts(products);
        } catch (err) {
            console.error("Failed to fetch products", err);
        }
    };

    useEffect(() => {
        gettingProducts();
    }, []);
    
    return <div className="products-body">
            <div className="products-container" >
                { standardFilter ? 
                    products.map((product) => {
                        { return product.grade == "Standard" ? 
                              <div key={product.id} className="products">
                                <Link to="/product" onClick={() => {
                                    setProductId(product.id);
                                }}>{product.name}</Link>
                                <div className="product-description">{product.description}</div>
                                <img className="product-image" src={product.image}/>
                                <div>Size: {product.oz} oz.</div>
                                <div>${product.priceperoz} per oz.</div>
                                <div>Grade: {product.grade}</div>
                            </div> : null
                        }
                    }) : null
                }
                { choiceFilter ? 
                    products.map((product) => {
                        { return product.grade == "Choice" ? 
                              <div key={product.id} className="products">
                                <Link to="/product" className="product-link" onClick={() => {
                                    setProductId(product.id);
                                }}>{product.name}</Link>
                                <div className="product-description">{product.description}</div>
                                <img className="product-image" src={product.image}/>
                                <div>Size: {product.oz} oz.</div>
                                <div>${product.priceperoz}/oz.</div>
                                <div>Grade: {product.grade}</div>
                            </div> : null
                        }
                    }) : null
                }
                { usdaFilter ? 
                    products.map((product) => {
                        { return product.grade == "USDA prime" ? 
                              <div key={product.id} className="products">
                                <Link to="/product" onClick={() => {
                                    setProductId(product.id);
                                }}>{product.name}</Link>
                                <div className="product-description">{product.description}</div>
                                <img className="product-image" src={product.image}/>
                                <div>Size: {product.oz} oz.</div>
                                <div>${product.priceperoz}/oz.</div>
                                <div>Grade: {product.grade}</div>
                            </div> : null
                        }
                    }) : null
                }
                { !usdaFilter && !choiceFilter && !standardFilter ? 
                    products.map((product) => {
                         return <div key={product.id} className="products">
                                <Link to="/product" onClick={() => {
                                    setProductId(product.id);
                                }}>{product.name}</Link>
                                <div className="product-description">{product.description}</div>
                                <img className="product-image" src={product.image}/>
                                <div>Size: {product.oz} oz.</div>
                                <div>${product.priceperoz}/oz.</div>
                                <div>Grade: {product.grade}</div>
                            </div>
                        
                    }) : null
                }
            </div>
        </div>
}