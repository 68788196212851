import React from "react"
import { useEffect, useState } from "react";
import { getProductReviews, getSingleProduct, addReview, addItemToCart } from "../api";
import { Link } from "react-router-dom";
import '../style/single_product.css'



export default function SingleProduct({ setProductId, productId, token, loggedIn, isAdmin, editProduct, setEditProduct, setProductName, setProductDescription, setPricePerOz, setGrade, setQuantity, quantity, setImage, userId, gettingCartByUser, cart }) {


    const [ product, setProduct ] = useState([]);
    const [ reviews, setReviews ] = useState([]);
    const [comment, setComment] = useState("");
    const [rating, setRating] = useState("");
    const [ alreadyInCart, setAlreadyInCart ] = useState(false);
    const [ reviewExists, setReviewExists ] = useState(false);

    const gettingSingleProduct = async () => {
        try {
            const product = await getSingleProduct(productId);
            const reviews = await getProductReviews(productId);
            setProduct(product);
            setReviews(reviews);
        } catch (err) {
            console.error("Unable to fetch single product", err);
        }  
    };

    const itemAlreadyInCart = () => {
        for(let i=0; i<cart.length; i++) {
            if(cart[i].products[0].id === productId) {
                setAlreadyInCart(true);
            }
        }
    };

    const reviewAlreadyExists = () => {
        for(let i=0; i<reviews.length; i++) {
            if(reviews[i].userId === userId) {
                setReviewExists(true);
            }
        }
    };

    useEffect(() => {
        gettingSingleProduct();
    }, []);

    useEffect(() => {
        itemAlreadyInCart();
    }, [cart]);

    useEffect(() => {
        gettingSingleProduct();
    }, [editProduct]);

    useEffect(() => {
        reviewAlreadyExists();
    }, [reviews]);

    return (
        <>
        <div> 
            <div className="single-product-container">
                <h1 className="single-product-headers">Product</h1>
                <div className="single-product">
                    <div>{product.name}</div>
                    <div>{product.description}</div>
                    <img className="single-product-image" src={product.image}/>
                    <div>Size: {product.oz} oz.</div>
                    <div>${product.priceperoz}/oz.</div>
                    <div>Grade: {product.grade}</div>
                    <div>Item remaining: {product.quantity}</div>
                    {
                        !alreadyInCart ? <form onSubmit={ async (event) => {
                            event.preventDefault();
                            await addItemToCart(userId, token, productId, quantity);
                            await gettingCartByUser();
                            setQuantity(0);
                        }}>
                            <label>Quantity</label>
                            <input type="number" min="1" max="999" maxLength="3" value={quantity} onChange={(event) => {setQuantity(event.target.value)}}></input>
                            <button type="submit">Add to Cart</button>
                        </form> : <div>Item in cart, click <Link to="/cart">Cart</Link> to edit</div>
                    }
                </div>
            </div>
            
            <br></br>
            {/* {isAdmin ? <button onClick={((event) => {
                event.preventDefault();
                setProductId(product.id);
                setProductName(product.name);
                setProductDescription(product.description);
                setPricePerOz(product.priceperoz);
                setGrade(product.grade);
                setQuantity(product.quantity);
                setImage(product.image);
                setEditProduct(true);
            })}>Edit Product</button> : null } */}
                <div className="my-reviews-container">
                    <h1 className="single-product-headers">Reviews</h1>
                        {
                        reviews[0] ? reviews.map((review) => {
                            return <div key={review.id} className="reviews">
                                <div>User: {review.username}</div>
                                <div>{review.comment}</div>
                                <div>Rating: {review.rating}</div>
                            </div>
                        }) : <div className="no-review-message">No reviews!</div>
                    }
                    {
                    loggedIn && !reviewExists ?
                <form onSubmit ={async (event)=> {
                        event.preventDefault()
                        await addReview (productId, rating, comment, token);
                        await gettingSingleProduct();
                        
                }}>
                    <textarea className="review-comment" type="text" placeholder="add comment" onChange={(event) => { setComment(event.target.value) }}></textarea>
                    <div className="review-rating-submit-container">
                        <input type="number" min="1" max="5" maxLength="1" placeholder="your rating" onChange={(event) => { setRating(event.target.value) }}></input>
                        <button type="submit"> Submit Rating</button>
                    </div>
                </form> : null
                }
                </div>
            </div>
        </>
    )
}